#profile-playlists {
  .playlist-container {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

    .video-card {
      width: calc((100% - var(--padding)) / 2);
    }
  }

  @media (min-width: 768px) {
    .playlist-container {
      .video-card {
        width: calc((100% - var(--padding) * 3) / 4);
      }
    }
  }
}
