#profile-bookmarks {
  .video-container {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

    .video-card {
      width: 100%;
      width: calc((100% - var(--padding)) / 2);
    }
  }

  .btn-sort {
    opacity: 0.5;
    transition: opacity 0.3s ease;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  @media (min-width: 768px) {
    .video-container {
      .video-card {
        width: calc((100% - var(--padding) * 3) / 4);
      }
    }
  }
}
