#page-search {
  background-color: var(--bs-light);
  flex: 1;
  padding: 3rem 0;

  .title {
    border-bottom: 5px solid var(--bs-orange);
    padding-bottom: 0.6rem;
    margin-bottom: 1rem;
  }

  .input-container {
    background-color: var(--bs-white);

    .icon {
      background-color: var(--bs-orange);
      color: var(--bs-white);
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      font-weight: bold;
      white-space: nowrap;
      min-width: 8em;
      justify-content: start;
    }

    input {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      flex: 1;
    }
  }

  .checkbox-container {
    background-color: var(--bs-white);
    padding: 0.5rem 1rem;
    margin-top: 0.5rem;
    border-radius: 6px;
  }

  .master-category {
    .MuiFormControlLabel-label {
      font-weight: bold;
    }
  }

  @media (min-width: 768px) {
    .sub-categories-container {
      margin-left: 28px;
    }
  }
}
