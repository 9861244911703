#page-profile {
  .banner {
    --banner-height: 64px;
    position: relative;
    z-index: 1;
  }

  .profile-header {
    position: relative;
    z-index: 2;

    .container {
      margin-top: 1em;
      display: flex;
      align-items: end;
    }

    .profile-picture {
      --size: 100px;
    }
  }

  .segment-container {
    display: flex;
    gap: 1rem;
    justify-content: space-around;
  }

  .segment-button {
    outline: none;
    border: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    opacity: 0.5;
    height: 30px;
    border-bottom: 3px solid transparent;
    transition: all 0.3s ease;
    font-size: 0.7rem;
    font-weight: bold;
    white-space: nowrap;

    img {
      height: 0.8rem;
    }

    &.active {
      color: var(--bs-primary);
      opacity: 1;
      border-bottom-color: var(--bs-primary);
    }
  }

  .profile-body {
    background-color: var(--bs-light);
    padding: 3rem 0;
  }

  .segment-body {
    background-color: var(--bs-white);
    border-radius: 20px;
    padding: 1rem 1rem 2rem;
    min-height: 530px;
  }

  .info-container {
    padding-top: 1rem;

    div {
      line-height: 1.5;
    }

    .subtitle {
      color: var(--bs-gray);
      font-weight: bold;
    }
  }

  @media (min-width: 768px) {
    .banner {
      --banner-height: 286px;
    }

    .profile-picture {
      --size: 100px;
    }

    .segment-container {
      justify-content: flex-start;
      gap: 2rem;
    }

    .segment-button {
      font-size: 1rem;
      gap: 9px;
      height: 48px;

      img {
        height: 1.2rem;
      }
    }

    .segment-body {
      padding: 3rem;
      position: relative;
    }
  }
}

footer {
  hr {
    border-color: transparent;
  }
}