.dnd-card {
  width: calc((100% - var(--padding)) / 2);
  img {
    object-fit: cover;
  }
  transform: translate(0, 0);
}
@media (min-width: 768px) {
  .dnd-card {
    width: calc((100% - var(--padding) * 3) / 4);
  }
}
