* {
  &[color="blue"] {
    --color: var(--bs-blue);
  }

  &[color="green"] {
    --color: var(--bs-green);
  }

  &[color="purple"] {
    --color: var(--bs-purple);
  }

  &[color="orange"] {
    --color: var(--bs-orange);
  }
}

.use-color-attrib {
  color: var(--color);
}

header {
  .navbar-nav {
    --bs-navbar-hover-color: var(--color);

    .nav-link {
        --bs-nav-link-hover-color: var(--color);
    }
  }

  .navbar-brand {
    order: 1;

    img {
      height: 30px;

      @media (min-width: 768px) {
        height: 68px;
        padding-top: 5px;
        padding-bottom: 15px;
      }
    }
  }

  .header-icons {
    order: 3;

    .navbar-nav {
      --bs-nav-link-padding-x: 0.5rem;
      flex-direction: row;
      align-items: center;

      svg {
        font-size: 1.2rem;
      }
    }

    svg {
      font-size: 1.2rem;
    }
  }

  .nav-item {
    .nav-link {
      font-weight: 600;
    }
  }

  #header-nav {
    order: 4;

    @media (min-width: 768px) {
      order: 2;
    }
  }

  .login-popup-trigger {
    cursor: pointer;
  }

  .login-popup-container {
    display: none;
    position: fixed;
    inset: 0;
    z-index: 100;
    align-items: center;
    justify-content: center;

    &.show {
      display: flex;
    }

    .login-popup-overlay {
      position: fixed;
      inset: 0;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(9px);
    }

    .login-box {
      z-index: 2;
    }

    .input-container {
      background-color: var(--bs-white);
    }
  }

  @media (min-width: 768px) {
    .login-popup-trigger {
      position: relative;
    }

    .login-popup-container {
      position: absolute;
      inset: 3rem 0 auto auto;
    }

    .login-popup-overlay {
      display: none;
    }
  }
}

footer {
  margin-top: auto;

  hr {
    margin-bottom: 3em;
  }

  .wrapper {
    display: flex;
    align-items: end;
    justify-content: space-between;
    width: 100%;

    .left {
      width: 100%;

      .logo-container {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        img {
          &:nth-child(1) {
            height: 36px;
            // same hight with right logo
            // height: 75px;
            // padding-bottom: 1px;
          }

          &:nth-child(2) {
            height: 75px;
          }
        }
      }

      .social-media-container {
        text-align: center;

        b {
          display: block;
          margin-bottom: 8px;
        }

        a {
          margin: 0 8px 0.5rem;
          display: inline-block;
        }

        svg {
          font-size: 45px;
          color: var(--bs-primary);
        }
      }

      .link-container {
        display: flex;
        gap: 8px;
        .btn {
          width: 100%;
          padding: 0.3rem;
          font-size: 0.8rem;
        }
      }
    }

    .right {
      img {
        height: 180px;
      }
    }

    @media (min-width: 768px) {
      .left {
        width: unset;

        .logo-container {
          justify-content: flex-start;

          img {
            height: 60px;
          }
        }

        .social-media-container,
        .link-container {
          display: flex;
          align-items: center;
          gap: 12px;

          b,
          a {
            margin: 0;
          }
        }

        .social-media-container {
          svg {
            font-size: 30px;
            color: var(--bs-primary);
          }
        }
      }
    }
  }

  .btn {
    color: var(--bs-white);
    --bs-btn-border-radius: calc(var(--bs-btn-font-size) * 2);
    --bs-btn-padding-x: 1rem;
  }

  .copyright {
    background-color: var(--bs-primary);
    color: var(--bs-white);
  }

  .MuiDialog-container {
    padding: 15px;
  }
}
