.banner {
  --banner-height: 200px;
  --banner-text-max-width: 75%;
  width: 100%;
  height: var(--banner-height);
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 1;
  }

  .wrapper {
    position: absolute;
    inset: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    color: var(--bs-green);
    line-height: 1;
    font-size: 2.5rem;

    strong {
      color: var(--bs-orange);
    }
  }

  .banner-title {
    font-size: 1.4rem;
  }

  small {
    font-size: 0.7em;
  }

  .banner-subtitle {
    font-size: 1.4rem;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
  }

  &.sub-page-banner {
    --banner-height: 120px;

    .text-container {
      max-width: var(--banner-text-max-width);
    }
  }

  @media (min-width: 768px) {
    --banner-height: 560px;

    .wrapper {
      font-size: 5rem;
    }

    .banner-title {
      font-size: 3rem;
    }

    .banner-subtitle {
      font-size: 2.5rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    &.sub-page-banner {
      --banner-height: 450px;
      --banner-text-max-width: 100%;

      img {
        object-position: 60%;
      }
    }
  }
}
