#page-login {
  background-color: var(--bs-white);
  width: 100vw;
  height: 100vh;
  display: flex;

  .image-container {
    height: 240px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .login-container {
    padding-bottom: 3rem;

    .logo-container {
      width: 100%;
      margin-top: 3rem;
      margin-bottom: -3rem;

      img {
        width: 60%;
        max-width: 180px;
        margin: 0 auto;
        display: block;
      }
    }

    .login-box {
      background-color: transparent;
      width: 100%;
    }

    .login-box-header {
      background-color: transparent;
    }

    .login-box-body {
      color: var(--bs-gray);
      padding-bottom: 0;
    }

    .login-box-title {
      color: var(--bs-primary);
      text-transform: uppercase;
    }
  }

  @media (min-width: 768px) {
    background-color: var(--bs-light);
    align-items: center;
    justify-content: center;

    .container {
      position: relative;
      border-radius: 30px;
      overflow: hidden;
    }

    .image-container {
      height: auto;
    }

    .login-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 3rem;

      .logo-container {
        margin-top: 0;
      }

      .login-box {
        max-width: 485px;
        margin: 0 auto;
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      max-width: unset;
    }
  }
}
