.star {
  --size: 24px;
  width: var(--size);
  height: var(--size);
  min-width: var(--size);
  min-height: var(--size);
  font-size: calc(var(--size) / 2);
  background-color: var(--bs-blue);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  svg {
    &[color="blue"] {
      color: var(--bs-blue);
    }
  }

  @media (min-width: 768px) {
    --size: 40px;
  }
}
