.list-pagination {
    /* This will be carried forward to --bs-pagination-color by _pagination.scss */
    --bs-link-color: var(--color);

    display: flex;
    margin-top: 15px;

    .list-pagination-summary {
        padding: 0.475rem 5px;
        font-size: 0.9rem;
    }

    .list-pagination-links {
        ul.pagination {
            margin-bottom: 0;
            .page-item {
                color: var(--bs-pagination-color);
                background-color: #f657020a;
                cursor: pointer;
            }
            .page-item.disabled {
                cursor: default !important;
                background-color: transparent;
                opacity: 0.5;
            }
            .page-arrow {
                scale: 0.9;
            }
            .page-link {
                border-radius: 4px;
                border-color: transparent;
                box-shadow: none;
                background-color: transparent;
            }
            .page-link:hover {
                background-color: #f657021f;
            }
            .page-active {
                background-color: var(--bs-pagination-color);
            }
            .page-active:hover {
                background-color: var(--bs-pagination-color);
            }
            li.disabled .page-link {
                color: var(--bs-pagination-color);
            }
        }
    }
}

