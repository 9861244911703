#page-home {
  .banner {
    z-index: 1;
  }

  .channel-container {
    margin-top: -2em;
    position: relative;
    z-index: 10;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    .color-card {
      font-size: 1rem;
      width: calc(50% - 4px);

      .color-card-content {
        flex: 1;
        min-height: 80px;
      }

      img {
        max-height: 70px;
        display: block;
        margin-left: auto;
        margin-bottom: -12px;
      }
    }

    @media (min-width: 768px) {
      flex-wrap: nowrap;

      .color-card {
        width: 25%;

        img {
          max-height: 120px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    .color-card {
      font-size: 1.2rem;
    }
  }
}
