#page-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;

  .title {
    font-size: 50px;
    line-height: 1;
    font-family: monospace;
  }

  .subtitle {
    margin: 3.5rem 0 3rem;
  }

  @media (min-width: 768px) {
    .title {
      font-size: 100px;
    }
  }
}
