.login-box {
  width: 90%;
  border-radius: 30px;
  overflow: hidden;
  background-color: var(--bs-secondary);

  .login-box-header {
    height: 124px;
    background-color: var(--bs-primary);
    display: flex;
    flex-direction: row-reverse;
    padding: 1.5em;

    .close-popup-trigger {
      color: #fff;

      svg {
        font-size: 1.7rem;
        font-weight: 500;
      }
    }
  }

  .login-box-body {
    color: var(--bs-white);
    padding: 0 1rem 3rem;

    a {
      color: var(--bs-white);
      text-decoration: none;
    }

    .input-container{
      input {
        padding: 10px;
      }
    }
  }

  .login-box-icon {
    --size: 96px;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    border: 3px solid var(--bs-white);
    margin: calc(var(--size) / -2) auto 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .login-box-title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }

  .btn {
    width: 100%;
  }

  @media (min-width: 768px) {
    width: 430px;

    .login-box-body {
      padding: 0 3rem 6rem;
    }
  }
}
