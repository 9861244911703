.popup {
    .popup-backdrop {
      position: fixed;
      inset: 0;
      z-index: 99;
      background-color: rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(9px);
    }

    form {
      position: fixed;
      top: 50%;
      left: 50%;
      z-index: 100;
      transform: translate(-50%, -50%);
      background-color: var(--bs-light);
      padding: 1rem;
      width: 90%;
      max-width: 740px;
      min-height: 260px;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
    }

    .button-container {
      margin-top: auto;
      display: flex;
      gap: .5rem;

      .btn {
        width: 100%;
        min-width: unset;
      }
    }

    @media (min-width: 768px) {
        .popup {
          .popup-backdrop {
            display: none;
          }
    
          form {
            position: absolute;
            padding: 3rem;
          }
        }
      }
  }