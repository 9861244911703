.video-card {
  padding-bottom: 1rem;

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1;
    min-height: 24px;
  }

  .video-card-thumbnail-container {
    width: 100%;
    position: relative;

    &::before {
      content: "";
      display: block;
      padding-bottom: calc(100% / 240 * 320);
    }

    .video-card-thumbnail-wrapper {
      position: absolute;
      inset: 0;
      z-index: 1;
      border-radius: 21px;
      overflow: hidden;
      background-color: var(--bs-dark);

      .video-card-thumbnail,
      .video-card-thumbnail-portrait {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .video-card-thumbnail {
        display: none;
      }
    }

    .video-card-overlay {
      position: absolute;
      inset: 0;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(75, 75, 75, 0.6);
      color: var(--bs-white);
      font-weight: bold;
      text-decoration: none;
    }

    .star {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      z-index: 3;
    }
  }

  .video-card-count-container {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    z-index: 3;
    background: var(--bs-black);
    color: var(--bs-white);
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    font-size: 0.75rem;

    img {
      height: 0.6rem;
    }
  }

  .video-card-button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    button {
      border: 0;
      outline: 0;
      background-color: transparent;
      opacity: 0.5;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: opacity 0.3s ease;
      padding: 0;

      &:hover {
        opacity: 1;
      }

      img {
        height: 15px;
      }

      span {
        color: var(--bs-dark);
        margin-left: 0.5rem;
        display: none;
        font-size: 0.8rem;
      }
    }
  }

  .video-card-hashtag-container {
    margin: 0 -0.5rem;

    a {
      color: var(--bs-blue);
      text-decoration: none;
      margin: 0 0.5rem;
      cursor: pointer;
    }
  }

  .video-date-container {
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    margin-bottom: 0.3rem;

    span {
      margin-top: 3px;
    }
  }

  .star-container{
    opacity: 1;
    transition: opacity 3s ease;
  }

  @media (min-width: 768px) {
    .video-card-count-container {
      font-size: 1rem;
      gap: 0.5rem;
      padding: 0.5rem 1rem;

      img {
        height: 15px;
      }
    }

    .video-card-button-container {
      gap: 0.5rem;

      button {
        span {
          display: inline;
        }
      }
    }

    .video-date-container {
      font-size: 1rem;
    }

    .video-card-thumbnail-container {
      &::before {
        padding-bottom: calc(100% / 320 * 240);
      }
    }

    .video-card-thumbnail {
      display: block !important;
    }

    .video-card-thumbnail-portrait {
      display: none !important;
    }
  }
}
