$theme-colors: (
  "light": #ebebeb,
  "dark": #313339,
  "primary": #fe5900,
  "secondary": #8c9099,
  "info": #ecc835,
  "success": #55a313,
  "warning": #febf5a,
  "danger": #f00961,
  "purple": #812f8e,
  "blue": #32a5dd,
  "green": #1eaf68,
  "orange": #fe5900,
);

@import "bootstrap";

*::-webkit-scrollbar {
  display: none;
}

a {
  color: inherit;
  text-decoration: none;
}

html {
  --bs-border-color: #8c9099;
  --padding: 16px;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  --bs-link-color: var(--bs-primary);
  --bs-link-hover-color: var(--bs-orange);
}

.btn:not(.btn-link) {
  padding: 0.6rem;
  min-width: 160px;
  text-align: center;
  font-weight: bold;
}

.btn-link {
  text-decoration: none;
}

.btn-primary,
.btn-secondary,
.btn-blue,
.btn-green,
.btn-purple,
.btn-orange {
  &:not(.btn-link) {
    --bs-btn-color: #fff;
    --bs-btn-hover-color: #fff;
    --bs-btn-active-color: #fff;
    --bs-btn-disabled-color: #fff;
  }
}

.btn-dark {
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-primary);
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
}

hr {
  border: var(--bs-border-width) solid var(--bs-border-color);
  opacity: 1;
}

.title {
  font-size: 1.2em;
  font-weight: bold;
}

.subtitle {
  font-size: 1.1em;
}

.swiper {
  .swiper-button-next,
  .swiper-button-prev {
    color: white;
  }
  .swiper-button-disabled {
    pointer-events: auto !important;
  }
}

.input-container {
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  background-color: var(--bs-light);
  border-radius: 6px;
  overflow: hidden;

  input {
    border: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    outline: none;
  }

  .icon {
    min-width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      height: 18px;
    }
  }

  @media (min-width: 768px) {
    .btn:not(.btn-link) {
      min-width: 194px;
    }
  }
}

.clickable {
  cursor: pointer;
}

.fade-out {
  opacity: 0 !important;
}

.swiper {
  --swiper-navigation-size: 18px;

  /* Hide swiper left/right buttons on mobile */
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    transition: all 0.3s ease;
    opacity: 1;
  }

  .swiper-button-disabled {
    opacity: 0;
  }

  /* Unhide swiper left/right buttons on tablet and above */
  @media (min-width: 768px) {
    --swiper-navigation-size: 24px;

    .swiper-button-prev,
    .swiper-button-next {
      display: flex;
      height: 100%;
      width: calc(20% / 4.8);
      margin: 0;
      top: 0;
    }
    .swiper-button-prev:hover,
    .swiper-button-next:hover {
      background-color: rgba(255, 255, 255, 0.4);
    }
    .swiper-button-prev {
      left: 0;
    }
    .swiper-button-next {
      right: 0;
    }
  }

  .swiper-slide {
    --width: calc(
      (100% - 90px /* partially shown slide on the far right */) / 4
        /* no. of cards per strip */
    );
    width: var(--width);
    min-width: var(--width);
  }
}

.vimeo-player-hover {
  position: absolute;
  inset: 0;
  z-index: 100;
  pointer-events: none !important; /* Prevent events from being intercepted by Vimeo */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #fff;
  overflow: hidden;
  transition: transform 0.3s ease, opacity 0.3s ease;
  border-radius: 21px;
}
