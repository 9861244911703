#video-detail {
  position: relative;

  .breadcrumb {
    margin-top: 1rem;
    margin-bottom: 1rem;
    --bs-breadcrumb-divider-color: #fff;
    font-size: 0.8rem;

    @media (min-width: 768px) {
      margin-top: 3rem;
      margin-bottom: 6rem;
      font-size: 1rem;
    }
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #add8e6, #d6eefa); /* Light blue gradient */

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .overlay {
      position: absolute;
      inset: 0 0 60%;
      background: linear-gradient(
                      0deg,
                      rgba(173, 216, 230, 0) 0%, /* Light blue with transparency */
                      rgba(176, 224, 230, 0.5) 91.67% /* Light blue with some opacity */
      );
      mix-blend-mode: multiply;
      z-index: 2;
    }
  }

  .main-container {
    padding-left: 0;
    padding-right: 0;

    @media (min-width: 768px) {
      padding-left: 10%;
      padding-right: 10%;
    }
  }

  .video-container {
    position: relative;

    .video-container-ratio {
      width: 100%;
      padding-bottom: calc(100% / 1365 * 766);
      position: relative;
    }

    .video-container-wrapper {
      position: absolute;
      inset: 0;
      > div {
        height: 100%;
      }
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .content-container {
    background-color: var(--bs-light);
    padding: 1.2rem 1rem;
    position: relative;

    .title {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      line-height: 1;
    }
    .tags-container {
      display: flex;
      flex-wrap: wrap;
    }
    @media (min-width: 768px) {
      padding: 3rem;
      .title {
        flex-direction: row;
        align-items: center;
        gap: 1rem;
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    align-items: start;
    gap: var(--padding);

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      --icon-size: 24px;

      .image {
        width: var(--icon-size);
        height: var(--icon-size);
      }

      img,
      svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &:nth-child(3) {
        img {
          height: 90%;
        }
      }

      .add-to-list-button {
        object-fit: fill;
      }

      .marked {
        filter: invert(70%) sepia(60%) saturate(10000%) hue-rotate(29deg)
        brightness(120%);
      }
    }

    @media (min-width: 768px) {
      justify-content: flex-end;
    }
  }

  .hashtag {
    color: var(--color);
  }
}

/* In "share-to" popup dialog */
.share-buttons > div {
  display: flex !important;
  gap: 1em;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 0.5em;

  > div {
    display: flex !important;
    margin: 0 !important;
  }
}
