#page-sub-channel {
  .folder-container {
    display: flex;
    align-items: start;
    flex-wrap: wrap;
  }

  .grow-space {
    flex-grow: 1;
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
    gap: var(--padding);

    .info-card {
      width: calc((100% - var(--padding)) / 2);
    }
  }

  .pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9px;

    .btn {
      --size: 48px;
      padding: 0;
      width: var(--size);
      height: var(--size);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (min-width: 768px) {
    .folder-container {
      flex-wrap: nowrap;
      .subtitle {
        text-align: center;
      }
    }

    .card-container {
      .info-card {
        width: calc(
          (100% - var(--padding) * 3 /* no. of cards per strip minus 1 */) / 4
            /* no. of cards per strip */
        );
      }
    }
  }
}
