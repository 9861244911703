.search-box {
  --collapse-icon-width: 35px;
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: var(--bs-white);
  align-items: center;
  z-index: 10;

  .icon {
    cursor: pointer;
  }

  input[type="search"] {
    padding-right: 0.5rem;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      --size: 18px;
      display: block;
      width: var(--size);
      height: var(--size);
      background-image: url(../../public/images/x-circle.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      opacity: 0.6;
      cursor: pointer;
    }
  }

  .input-container {
    transition: all 0.3s ease;
    max-width: 1000px;
    background-color: var(--bs-white);

    input {
      transition: all 0.4s ease;
    }

    form {
      flex: 1;
    }
  }

  &.hide {
    position: relative;
    height: unset;
  }

  &.closing {
    position: absolute;
    height: 60px;
  }

  &.hide,
  &.closing {
    .input-container {
      background-color: var(--bs-white);
      max-width: var(--collapse-icon-width);
      overflow: hidden;

      input {
        padding: 0;
      }
    }
  }

  @media (min-width: 768px) {
    --collapse-icon-width: 38px;
    position: relative;
    height: unset;

    &.closing {
      position: relative;
    }
  }
}
