#profile-edit {
  .info-container {
    display: flex;
    gap: 1rem;
    flex-direction: column;
  }

  .profile-picture-container {
    .wrapper {
      position: relative;
      display: inline-block;
    }

    .profile-picture {
      --size: 120px;
    }

    button {
      --size: 50px;
      outline: none;
      border: 0;
      border-radius: 50%;
      width: var(--size);
      height: var(--size);
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 27px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .user-detail-container {
    flex: 1;

    .detail {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
      line-height: 38px;
      white-space: nowrap;

      .label {
        min-width: 7em;
        font-weight: bold;
      }

      .colon {
        font-weight: bold;
      }

      .value {
        padding: 0 0.6rem;
      }

      .input-container {
        padding: 0 0.6rem;
      }
    }

    &.view {
      .input-container {
        display: none;
      }
    }

    &.edit {
      span {
        display: none;
      }
    }

    .button-container {
      .btn {
        width: 100%;
      }
    }
  }

  @media (min-width: 768px) {
    .info-container {
      flex-direction: row;
      gap: 3rem;
    }

    .profile-picture-container {
      .profile-picture {
        --size: 142px;
      }
    }

    .user-detail-container {
      .button-container {
        .btn {
          width: auto;
        }
      }
    }
  }
}
