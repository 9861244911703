.back-to-top {
  position: fixed;
  width: 100%;
  bottom: 16px;
  z-index: 10;
  height: 0;
  overflow: visible;
  opacity: 0;
  transition: all 0.3s ease;
  --size: 48px;

  &.show {
    opacity: 1;
  }

  .container {
    height: 0 !important;
    overflow: visible;
    display: flex;
    justify-content: flex-end;
  }

  button {
    background-color: var(--bs-dark);
    color: #fff;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    padding: 0;
    margin: 0;
    border: 0;
    font-size: calc(var(--size) / 2);
    transform: translateY(-100%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 768px) {
    --size: 60px;
  }
}
