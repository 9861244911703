.info-card {
  position: relative;

  .info-card-ratio {
    width: 100%;
    border-radius: 21px;
    overflow: hidden;
    /* Card dimensions */
    padding-bottom: calc(100% / 240 * 320);
    position: relative;
  }

  .info-card-wrapper {
    position: absolute;
    inset: 0;
  }

  .info-card-background,
  .info-card-background-portrait {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: relative;
    z-index: 1;
  }

  .info-card-background {
    display: none;
  }

  .info-card-overlay {
    position: absolute;
    inset: 0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.45);
    transition: all 0.3s ease;
    border-radius: 24px;
  }

  .info-card-color-overlay {
    position: absolute;
    inset: 60% 0 0;
    background: linear-gradient(180deg, rgba(129, 47, 142, 0) 0%, var(--color) 91.67%);
    mix-blend-mode: multiply;
    z-index: 2;
    transition: all 0.3s ease;
    border-radius: 24px;
  }

  .info-card-content {
    opacity: 1;
    transition: opacity 3s ease;
  }

  .info-card-icon {
    --size: 36px;
    width: var(--size);
    min-height: var(--size);
    font-size: calc(var(--size) / 2);
    background-color: var(--color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info-card-title {
    font-weight: bold;
    font-size: 1em;
  }

  .info-card-content {
    position: absolute;
    inset: 0;
    z-index: 200;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    padding: 1rem;
    gap: 9px;
  }

  .btn {
    width: 80%;
    // position: absolute;
    // z-index: 200;
  }

  .has-video-preview &:hover {
    .info-card-overlay,
    .info-card-color-overlay {
      background: transparent;
    }
  }

  .info-card-description {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    position: relative;
    // Try to show 2 lines of descriptions before trimming
    // white-space: nowrap;
    // min-height: 24px;
    display: -webkit-box;
    max-height: 3em;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: .9rem;
  }

  @media (min-width: 768px) {
    .info-card-icon {
      --size: 40px;
    }

    .info-card-background {
      display: block;
      object-position: right;
    }

    .info-card-background-portrait {
      display: none;
    }

    .info-card-ratio {
      /* Card dimensions */
      padding-bottom: calc(100% / 320 * 240);
    }

    .info-card-content {
      padding: 1rem 2rem;
    }

    .info-card-title {
      font-size: 1.3em;
    }

    .info-card-description {
      font-size: 1rem;
    }
  }

  @media (max-width: 430px) {
    .info-card-description {
      overflow:hidden;
      white-space:nowrap;
      text-overflow:ellipsis;
      width: 125px;
      display: block;
      padding: 1rem;
    }
  }

}
