
.go-to-page-popover {
    position: absolute;
    display: flex;
    border-radius: 5px;
    .popover-label {
        width: max-content;
        padding: 7px 10px;
        background-color: #d3d3d347;
        cursor: default;
    }
    input {
        width: 50px;
        border: none;
    }
    //===== hide arrow ======
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }
    //===== hide arrow ======
    button {
        border: none;
        padding: 7px 10px;
        color: white;
        background-color: var(--bs-pagination-color);
        border-radius: 0 5px 5px 0;
    }
}
