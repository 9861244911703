.color-card {
  color: #fff;
  border-radius: 21px;
  overflow: hidden;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 0.5rem;

  &[color="blue"] {
    background: linear-gradient(
      146deg,
      #84d5f7 0%,
      #74ccf2 10%,
      #57bbe9 33%,
      #43afe2 56%,
      #36a7de 78%,
      #32a5dd 100%
    );
  }

  &[color="green"] {
    background: linear-gradient(
      146deg,
      #add136 0%,
      #8ac942 14%,
      #63bf50 31%,
      #45b85a 49%,
      #2fb362 66%,
      #22b066 82%,
      #1eaf68 97%,
      #11ad63 98%,
      #04ab5f 99%,
      #00aa5d 100%
    );
  }

  &[color="purple"] {
    background: linear-gradient(
      146deg,
      #c381ea 0%,
      #b26cd2 14%,
      #9d51b5 37%,
      #8d3e9f 59%,
      #843392 80%,
      #812f8e 100%
    );
  }

  &[color="orange"] {
    background: linear-gradient(
      146deg,
      #ffc40c 0%,
      #fdbd0e 5%,
      #f6a017 29%,
      #f18c1e 53%,
      #ee7f22 77%,
      #ed7b23 100%
    );
  }

  @media (min-width: 768px) {
    padding: 1rem;
  }
}
