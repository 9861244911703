#page-channel {
  .sub-channel-container {
    .title {
      color: #fff;
      display: flex;
      align-items: center;
      gap: 8px;
      line-height: 1;
      padding: .7rem 1rem;

      /* Left-to-right opacity gradient fading */
      background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));

      .icon {
        --size: 21px;
        background: #fff;
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: calc(var(--size) / 2);
      }

      span {
        font-weight: 300;
      }
    }
  }

  @media (min-width: 768px) {
    .sub-channel-container {
      .subtitle {
        .icon {
          --size: 40px;
        }
      }

      .content {
        padding: 0 calc(1rem + 40px + 8px);
      }
    }
  }
}
